import request from '@http';

// 租金回笼列表
export function getBillRentList(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/bills/rent/list',
        params
    })
}
// 租金回笼 本年租金eCharts
export function getBillRentLine(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/bills/rent/line',
        params
    })
}
// 租金回笼 旧欠eCharts
export function getBillRentJQline(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/bills/rent/JQline',
        params
    })
}
//租金回笼 导出
export function rentExport(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/bills/rent/export',
        responseType: 'blob',
        params
    })
}

//水电费列表
export function getBillWaterelectList(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/bills/waterelect/list',
        params
    })
}

//水电费列表 已收eCharts
export function getBillWaterelectLine(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/bills/waterelect/line',
        params
    })
}

//水电费列表 未收eCharts
export function getBillWaterelectUnLine(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/bills/waterelect/uncollectedline',
        params
    })
}

//水电费 导出
export function waterelectExport(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/bills/waterelect/export',
        responseType: 'blob',
        params
    })
}

//押金列表
export function getBillEarnestList(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/bills/earnest/list',
        params
    })
}

//押金  折线图
export function getBillEarnestLine(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/bills/earnest/line',
        params
    })
}
//押金 饼状图
export function getBillEarnestSector(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/bills/earnest/sector',
        params
    })
}

//押金 导出
export function earnestExport(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/bills/earnest/export',
        responseType: 'blob',
        params
    })
}

// 出租率 列表
export function getBillsLettingList(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/bills/letting/list',
        params
    })
}
// 出租率折线图接口
export function getBillsLettingLine(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/bills/letting/rentout/line',
        params
    })
}

// 面积出租率折线图接口
export function getBillsLettingNorentoutLine(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/bills/letting/norentout/line',
        params
    })
}

// 租金折线图
export function getBillsRentalLine(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/bills/rental/line',
        params
    })
}

// 租金折线图
export function getBillsServiceLine(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/bills/service/line',
        params
    })
}

// 租金统计列表
export function getBillsRentalList(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/bills/rental/list',
        params
    })
}